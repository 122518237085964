import style from './style.module.scss';

const ModalIssues = (props) => {
    if (!props.isOpen) return null;

    return (
        <div className={style.modal}>
            <div className={style.modal_codes}>
                <div className={style.modal_close} onClick={() => props.onClick()}>X</div>
                <p><b>Payment Issues?</b></p>
                <p>If you're experiencing payment issues, please check the box "<i>I was unable to make the payment</i>" and complete your registration. We'll get in touch with you shortly with further instructions.</p>
                <p>Feel free to contact us at <a href="mailto:finance.unitytwg@gmail.com">finance.unitytwg@gmail.com</a> if needed!</p>
            </div>
        </div>
    );
}

export default ModalIssues;