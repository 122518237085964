import React from 'react';
import style from './style.module.scss';

const Button = props => {
    return (
        <div className={[style.button, props.className, props.loading ? style.button_loading : '', 'button'].join(' ')} onClick={() => props.onClick()}>
            <span className={style.button__text}>{props.title}</span>
        </div>
    );
};

export default Button;