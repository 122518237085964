import React from 'react';
import { gsap } from "gsap";
import Countdown from 'react-countdown';
import CountUp from 'react-countup';

import RegisterButton from '../UI/button';

// import bannerImageLogo from '../../assets/img/logo2024.png';
// import bannerInnerLogo from '../../assets/img/logoinner2024.png';
// import bannerImage from '../../assets/gif/twg2.gif';
import bannerImage from '../../assets/img/logo_2025.jpg';
import style from './style.module.scss';

const Banner = props => {

    React.useEffect(() => {      
        gsap.fromTo('.title', {opacity: 0, x: -200 }, { opacity: 1 , x: 0 , duration: 1 });
        gsap.fromTo('.subtitle', {opacity: 0, x: -200 }, { opacity: 1 , x: 0 , duration: 1, delay: .5 });
        gsap.fromTo('.register', {opacity: 0, x: -200 }, { opacity: 1 , x: 0 , duration: 1, delay: .5 });
        // gsap.fromTo('.counter', {opacity: 0, x: -200 }, { opacity: 1 , x: 0 , duration: 1, delay: .5 });
    }, []);

    const handleScroll = () => {
        const element = document.getElementById("register");
        element.scrollIntoView();
    }

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        return (
            <div className={[style.counter, 'counter'].join(' ')}>
                <div className={style.counter_box}><p><CountUp className={style.number} end={days} duration={1}/></p><span>days</span></div>:
                <div className={style.counter_box}><p><CountUp className={style.number} end={hours} duration={1}/></p><span>hours</span></div>:
                <div className={style.counter_box}><p><CountUp className={style.number} end={minutes} duration={1}/></p><span>minutes</span></div>:
                <div className={style.counter_box}><p>{seconds}</p><span>seconds</span></div>
            </div>
        );
    };

    return (
        <div className={style.banner}>
            <div className={style.banner_title}>
                <h1 className='title'>{props.title}</h1>
                {props.subtitle && <p className='subtitle'>{props.subtitle}</p>}
                {props.counter && <Countdown date='2025-05-01T08:00:00' renderer={renderer}/>}
                {props.counter && <RegisterButton title="Register" className={[style.registerButton, 'register'].join(' ')} onClick={() => handleScroll()} />}
            </div>
            {/* <div className={style.logo}>
                <img src={bannerInnerLogo}/>
                <img className={style.logo_move} src={bannerImageLogo}/>
            </div> */}
            <img src={bannerImage}/>
        </div>
    );
};

export default Banner;