import {
	BrowserRouter,
	Routes ,
	Route
  } from "react-router-dom";

import Homepage from './components/homepage';
import About from './components/about';
import Fest from './components/fest';
import Layout from './components/layout';
import Climbing from './components/climbing';

const App = () => {
	return (
		<Layout>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Fest />} />
					<Route path="/about" element={<About />} />
					<Route path="/twg-fest" element={<Fest />} />
					<Route path="/climbing" element={<Climbing />} />
				</Routes>
			</BrowserRouter>
		</Layout>
	);
};

export default App;
