import React from 'react';

import style from './style.module.scss';

const RadioButton = props => {
    const [ checked, setChecked ] = React.useState(props.checked || false);

    const handleChange = e => {
        setChecked(!checked);
        props.onChecked(e.target.value, !checked);
    }

    return (
        <div className={[style.radiobutton, props.className].join(' ')}>
          <input checked={checked} type="radio" id={props.id} value={props.text} onClick={e => handleChange(e)}/>
          <label htmlFor={props.id}>{props.text}</label>
       </div>
    );
};

export default RadioButton;