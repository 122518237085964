import React, { useMemo, useRef }  from 'react';
import axios from 'axios';
import style from './style.module.scss';
import Login from '../login';
import Button from '../UI/button';

const Climbing = props => {
    const [user, setUser] = React.useState(null);
    const [userStatusView, setUserStatusView] = React.useState(true);

    const [list, setList] = React.useState([]);
    const [myRoutes, setMyRoutes] = React.useState([]);
    const [routes, setRoutes] = React.useState([]);

    React.useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            setUser(user);
        }
    }, []);

    React.useEffect(() => {
        if (user) {
            if (list.length === 0) {
                axios.get('https://together-wegrow.com/api/v1/climbing_user/users').then((response) => {
                    setList(response.data.sort((a, b) => b.points - a.points));
                });
            }
            if (myRoutes.length === 0) {
                axios.get(`https://together-wegrow.com/api/v1/climbing_user/user?username=${user.username}`).then((response) => {
                    setMyRoutes(response.data.map(item => { if (item.climbed) return { id: item.id, name: item.name, grade: item.grade, length: item.length, points: item.points }}).filter(i => i));
                    setRoutes(response.data.map(item => { if (!item.climbed) return { id: item.id, name: item.name, grade: item.grade, length: item.length, points: item.points }}).filter(i => i));
                });
            }
        }
    }, [user]);

    const reload = () => {
        axios.get('https://together-wegrow.com/api/v1/climbing_user/users').then((response) => {
            setList(response.data.sort((a, b) => b.points - a.points));
        });
    }

    const handleAddRoute = async (route) => {
        await axios.post('https://together-wegrow.com/api/v1/climbing_user/user', { routeId: route.id, username: user.username });
        const tempMyRoutes = [...myRoutes, route];
        setMyRoutes(tempMyRoutes);
        const temproutes = routes.filter(item => item.id !== route.id);
        setRoutes(temproutes);
        const tempList = list.map(item => {
            if (item.username === user.username) {
                return {
                    ...item,
                    points: item.points + route.points
                };
            } else {
                return { ...item };
            }
        });
        setList(tempList.sort((a, b) => b.points - a.points));
    }

    const handleRemoveRoute = async (route) => {
        await axios.delete(`https://together-wegrow.com/api/v1/climbing_user/user?routeId=${route.id}&username=${user.username}`);
        const tempMyRoutes = myRoutes.filter(item => item.id !== route.id);
        setMyRoutes(tempMyRoutes);
        const temproutes = [...routes, route];
        setRoutes(temproutes);
        const tempList = list.map(item => {
            if (item.username === user.username) {
                return {
                    ...item,
                    points: item.points - route.points
                };
            } else {
                return { ...item };
            }
        });
        setList(tempList.sort((a, b) => b.points - a.points));
    }

    const handleLogout = () => {
        localStorage.setItem('user', '');
        setList([]);
        setMyRoutes([]);
        setRoutes([]);
        setUser(null);
    }

    return (
        <div className={style.climbing}>
            {!user
                ? <Login handleLoggedIn={userData => setUser(userData)} />
                : <div className={style.climbing_wrapper}>
                    <div className={style.climbing_heading}>
                        <h3>{`Hi ${user.user}, welcome to TWG Climbing contest and enjoi! Be fair part of the game!`}</h3>
                        <Button title="logout" className={style.climbing_heading_logout} onClick={() => handleLogout()} />
                    </div>
                    <div className={style.climbing_selection}>
                        <span className={userStatusView ? style.active : ''} onClick={() => setUserStatusView(true)} >Ranking List</span>
                        <span className={!userStatusView ? style.active : ''} onClick={() => setUserStatusView(false)}>My Routes</span>
                    </div>
                    {userStatusView
                        ? <div>
                            <div className={style.climbing_climber}>
                                <h4>Ranking List</h4>
                                <svg onClick={() => reload()} fill="#000000" height="200px" width="200px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 489.645 489.645" ><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M460.656,132.911c-58.7-122.1-212.2-166.5-331.8-104.1c-9.4,5.2-13.5,16.6-8.3,27c5.2,9.4,16.6,13.5,27,8.3 c99.9-52,227.4-14.9,276.7,86.3c65.4,134.3-19,236.7-87.4,274.6c-93.1,51.7-211.2,17.4-267.6-70.7l69.3,14.5 c10.4,2.1,21.8-4.2,23.9-15.6c2.1-10.4-4.2-21.8-15.6-23.9l-122.8-25c-20.6-2-25,16.6-23.9,22.9l15.6,123.8 c1,10.4,9.4,17.7,19.8,17.7c12.8,0,20.8-12.5,19.8-23.9l-6-50.5c57.4,70.8,170.3,131.2,307.4,68.2 C414.856,432.511,548.256,314.811,460.656,132.911z"></path> </g> </g></svg>
                            </div>
                            <div className={style.list}>
                                <div className={style.list_row}>
                                    <span className={[style.list_heading, style.list_row_number].join(' ')}>no.</span>
                                    <span className={style.list_heading}>Name</span>
                                    <span className={style.list_heading}>Points</span>
                                </div>
                                {list.map((item, index) => <div className={[style.list_row, item.username === user.username ? style.logedinuser : ''].join(' ')}>
                                        <span className={style.list_row_number}>{index + 1}</span>
                                        <span>{item.name}</span>
                                        <span>{item.points}</span>
                                </div>)}
                            </div>
                        </div>
                        : <div>
                            <div className={style.climbing_climber}>
                                <h4>Climbed</h4>
                                <h4>My points: {myRoutes.reduce((acc, curr) => acc + curr.points, 0)}</h4>
                            </div>
                            <div className={style.climbing_myroutes}>
                                {myRoutes.length ? <div className={style.list}>
                                    <div className={style.list_row}>
                                        <span className={style.list_heading}>Name</span>
                                        <span className={style.list_heading}>Grade</span>
                                        <span className={style.list_heading}>Length</span>
                                        <span className={style.list_heading}>Points</span>
                                    </div>
                                    {myRoutes.map(item => <div className={style.list_row} onClick={() => handleRemoveRoute(item)}>
                                            <span>{item.name}</span>
                                            <span>{item.grade}</span>
                                            <span>{item.length}</span>
                                            <span>{item.points}</span>
                                    </div>)}
                                </div> : 'No route climbed yet'}
                            </div>
                            <div className={style.climbing_climber}>
                                <h4>All Routes</h4>
                            </div>
                            <div className={style.climbing_myroutes}>
                                <div className={style.list}>
                                    <div className={style.list_row}>
                                        <span className={style.list_heading}>Name</span>
                                        <span className={style.list_heading}>Grade</span>
                                        <span className={style.list_heading}>Length</span>
                                        <span className={style.list_heading}>Points</span>
                                    </div>
                                    {routes.map(item => <div className={style.list_row} onClick={() => handleAddRoute(item)}>
                                            <span>{item.name}</span>
                                            <span>{item.grade}</span>
                                            <span>{item.length}</span>
                                            <span>{item.points}</span>
                                    </div>)}
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    );
};
export default Climbing;