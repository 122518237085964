import style from './style.module.scss';
import QRCode from '../../../assets/img/QR_code_payment.png';
import DCode from '../../../assets/img/2D_code_payment.png';

const Modal = (props) => {
    if (!props.isOpen) return null;

    const copyToClipboard = (elementId) => {
        var copyText = document.getElementById(elementId);
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(copyText.value);
    }

    return (
        <div className={style.modal}>
            <div className={style.modal_codes}>
                <div className={style.modal_close} onClick={() => props.onClick()}>X</div>
                <div><img src={QRCode} /></div>
                <div><img src={DCode} /></div>
                <div className={style.modal_codes_iban}>
                    <div className={style.divider}>
                        <hr/>
                        <span>or using iban</span>
                        <hr/>
                    </div>
                    <div className={style.modal_ibanData}>
                        IBAN:
                        <div className={style.payment_iban}>
                            <input type="text" value="HR2024020061100894281" id="iban" />
                            <i className={style.copy} onClick={() => copyToClipboard('iban')}></i>
                        </div>
                    </div>
                    <div className={style.modal_ibanData}>
                        Comment/Note:
                        <div className={style.payment_iban}>
                            <input type="text" value="Radionica" id="comment" />
                            <i className={style.copy} onClick={() => copyToClipboard('comment')}></i>
                        </div>
                    </div>
                    <div>With payment reference/Poziv na broj: 2025</div>
                </div>
            </div>
        </div>
    );
}

export default Modal;